<template>
    <div class="fan-analysis">
        <div class="analysis-top">
            <div class="analysis-left">
                <div class="gender-distribution">
                    <span class="title">性别分布</span>
                    <div class="distribution-chart">
                        <div class="sex-name">
                            <span class="name-span">{{woman}}%</span>
                            <span class="name-span">{{man}}%</span>
                        </div>
                        <div class="chart-content">
                            <div class="mab" :style="{width: woman+'%'}">
                                <div class="mab-color"></div>
                            </div>
                            <div class="woman">
                                <div class="woman-color"></div>
                            </div>
                        </div>
                        <div class="gender-icon">
                            <div class="gender-icon-left">
                                <i class="iconfont">&#xe6a8;</i>
                                <span>女性用户</span>
                            </div>
                            <div class="gender-icon-right">
                                <i class="iconfont">&#xe6a6;</i>
                                <span>男性用户</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="analysis-right">
                <div class="age-distribution">
                    <span class="title">年龄分布</span>
                    <div id="ageDistribution" style="width: 100%;height: 275px;position: relative"></div>
                </div>
            </div>
        </div>
        <div class="analysis-bottom">
            <span class="title">地区分布</span>
            <div class="two-title">
                <span>名称</span>
                <span>占比</span>
            </div>
            <div class="area-distribution">
                <div class="area-item-box" v-if="areaData.length > 0">
                    <div class="area-item" v-for="item in areaData">
                        <span class="area-name">{{item.areaName}}</span>
                        <div class="area-progress-box">
                            <div class="area-progress-num" :style="{width:item.percentage + '%'}"></div>
                        </div>
                        <span class="percentage text-overflow">{{item.value}}%</span>
                    </div>
                </div>
                <div v-else class="null-data-area">暂无数据</div>

                <!--          <div class="content">-->
                <!--            <div  v-if="areaData.length > 0">-->
                <!--              <div class="area-item" v-for="item in areaData">-->
                <!--                <span class="area-name">{{item.areaName}}</span>-->
                <!--                <el-progress :percentage="item.percentage" :show-text="false":stroke-width="12"></el-progress>-->
                <!--                <span class="percentage" style="width: 50px">{{item.value}}%</span>-->
                <!--              </div>-->
                <!--            </div>-->
                <!--            <div v-else class="null-data-area">暂无数据</div>-->
                <!--          </div>-->
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "FanDataModule",
        data() {
            return {
                man: 0,
                woman: 0,
                age: [],
                areaData: [],
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            getData(){
                this.ageDistribution = this.$echarts.init(document.getElementById('ageDistribution'));
                window.onresize = () => {
                    this.ageDistributionFun(this.age);
                };
                if (this.$route.query.id) {
                    let param = {
                        platform_type: 1,
                        type:1,
                        id: this.$route.query.id
                    };
                    this.$httpteacher.axiosGetBy(this.$api.get_account, param, res => {
                        if (res.code == 200) {
                            //男女比例
                            //計算男女之和
                            if (res.data.fans_data.gender_distributions && res.data.fans_data.gender_distributions.length>0){
                                let sum = parseInt(res.data.fans_data.gender_distributions[0].value) + parseInt(res.data.fans_data.gender_distributions[1].value);
                                let genderData = res.data.fans_data.gender_distributions;
                                if (typeof(genderData) == 'string'){
                                    //判断类型是否为字符串，如果是字符串转为JSON格式
                                    genderData = JSON.parse(
                                        genderData
                                    );
                                }
                                this.man = Math.round(genderData[0].value / sum * 10000) / 100.00;
                                this.woman = Math.round(genderData[1].value / sum * 10000) / 100.00;
                            } else {
                                this.man = 50;
                                this.woman = 50
                            }
                            // 年龄段
                            if (res.data.fans_data.age_distributions && res.data.fans_data.age_distributions.length>0){
                                let ageData = res.data.fans_data.age_distributions;
                                if (typeof(ageData) == 'string'){
                                    //判断类型是否为字符串，如果是字符串转为JSON格式
                                    ageData = JSON.parse(
                                        ageData
                                    );
                                }
                                this.age = ageData
                            } else {
                                this.age = [];
                            }
                            this.ageDistributionFun(this.age);
                            // this.areaDataFun(res.data.geographical_distributions);
                            // 地区分布
                            if (res.data.fans_data.geographical_distributions && res.data.fans_data.geographical_distributions.length>0){
                                let areaData = res.data.fans_data.geographical_distributions;
                                if (typeof(areaData) == 'string'){
                                    //判断类型是否为字符串，如果是字符串转为JSON格式
                                    areaData = JSON.parse(
                                        areaData
                                    );
                                }
                                this.areaDataFun(areaData)
                            } else {
                                this.areaDataFun([])
                            }

                        } else {
                            this.$message({
                                type: "error",
                                message: res.message,
                                duration: 1000
                            });
                            this.ageDistributionFun([]);
                        }
                    }, (err) => {
                        this.ageDistributionFun([]);
                        console.log(err)
                    })
                }
            },
            ageDistributionFun(array) {
                //年龄段数据处理
                let noDataDiv = document.querySelector('.no-data');
                if(noDataDiv !== null){
                    document.querySelector("#ageDistribution").removeChild(noDataDiv);
                }
                if (document.getElementById('ageDistribution')){
                    let ageArray = [],
                        dataArray = [],
                        totalNum = 0;
                    if (array.length>0){
                        for (let i in array) {
                            totalNum += Number(array[i].value)
                        }
                        for(let j in array){
                            ageArray.push(array[j].item);
                            let obj = {
                                value:Number(this.getPercent(array[j].value,totalNum,false)),
                                name:array[j].item
                            }
                            dataArray.push(obj)
                        }
                    }
                    ageArray.sort();
                    let _this =this;
                    this.ageDistribution = this.$echarts.init(document.getElementById('ageDistribution'));
                    this.ageDistribution.setOption({
                        tooltip : { //提示框组件
                            trigger: 'item', //触发类型(饼状图片就是用这个)
                            formatter:function (params){
                                return params.seriesName + '<br/>' + params.name + ':' + params.value+'%'
                            }
                        },
                        color:['#9ECB59','#5F55F1','#C3C3F4','#F5697B','#F5AF23'],
                        legend: {
                            padding:[0,60,0,0],
                            orient : 'horizontal',  //布局  纵向布局 图例标记居文字的左边 vertical则反之
                            width:40,      //图行例组件的宽度,默认自适应
                            x : 'right',   //图例显示在右边
                            y: 'bottom',   //图例在垂直方向上面显示居中
                            itemWidth:10,  //图例标记的图形宽度
                            itemHeight:10, //图例标记的图形高度
                            data:ageArray,
                            textStyle:{
                                color:'#000',
                                fontSize:14,
                                lineHeight:22
                            },
                            formatter: function (params) {
                                return params + '岁'
                            }
                        },
                        series: [{
                            name: '年龄分布',
                            type: 'pie',
                            data: dataArray,
                            center:['40%','58%'],
                            radius : ['60%', '70%'],
                            itemStyle:{
                                normal:{
                                    label : {  //饼图图形上的文本标签
                                        show : true,  //平常不显示
                                        color:'#000',
                                        fontSize:18,
                                        formatter: function (params) {
                                            return params.value + '%';
                                        }
                                    },
                                    labelLine : {     //标签的视觉引导线样式
                                        show : true  //平常不显示
                                    },
                                },
                                emphasis:{
                                    label:{
                                        show : true,
                                        position : 'center',
                                        textStyle : {
                                            fontSize : '10',
                                            fontWeight : 'bold'
                                        }
                                    }
                                }
                            }
                        }]
                    });

                    if(dataArray.length==0){
                        let div1 = document.createElement("div");
                        div1.innerHTML = "暂无数据";
                        div1.className = 'no-data';
                        div1.style.position = "absolute";
                        div1.style.top = "100px";
                        div1.style.left = "50%";
                        document.querySelector("#ageDistribution").appendChild(div1)
                    }
                }

            },
            //计算百分比
            getPercent(curNum, totalNum, isHasPercentStr){
                curNum = parseFloat(curNum);
                totalNum = parseFloat(totalNum);
                if(isNaN(curNum) || isNaN(totalNum)){
                    return '-';
                }
                return isHasPercentStr ?
                    totalNum <= 0 ? '0%' : (Math.round(curNum / totalNum * 10000) / 100.00 + '%') :
                    totalNum <= 0 ? 0 : (Math.round(curNum / totalNum * 10000) / 100.00);
            },
            areaDataFun(area) {
                //地区数据处理
                this.areaData = [];
                if(area.length){
                    let maxNum = area.sort((a, b) => b.value - a.value)[0].value;
                    let totalNum = 0;
                    area.forEach(item=>{
                        totalNum += Number(item.value)
                    })
                    let leng = area.length > 10 ? 10 : area.length;
                    for(let i = 0; i < leng; i++){
                        let obj = {
                            areaName:area[i].item,
                            percentage:this.getPercent(area[i].value,maxNum),
                            value:this.getPercent(area[i].value,totalNum)
                        }
                        this.areaData.push(obj)
                    }
                } else {
                    this.areaData = [];
                }
            }
        },

    }
</script>

<style scoped lang="scss">
    .fan-analysis {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-bottom: 20px;
        .analysis-top{
            display: flex;
            border-bottom: 5px solid #EEEEEE;
            padding-bottom: 34px;
            margin: 0 30px;
            .analysis-left {
                display: flex;
                flex-direction: column;
                width: 50%;
                height: 100%;
                border-right: 5px solid #EEEEEE;
                padding: 0 54px 0 24px;
                .gender-distribution {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    .title {
                        font-size: 14px;
                        font-weight: 400;
                        color: #333;
                    }
                    .distribution-chart {
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                        height: 1%;
                        justify-content: center;
                        .sex-name{
                            display: flex;
                            justify-content: space-between;
                            .name-span{
                                color: #000000;
                                font-size: 18px;
                                line-height: 1;
                            }
                        }
                        .chart-content{
                            width: 100%;
                            display: flex;
                            .mab{
                                height: 100%;
                                display: flex;
                                flex-direction: column;
                                text-align: center;
                                .mab-color{
                                    background: #FF6A6A;
                                    border-radius: 7px;
                                    height: 12px;
                                    margin-top: 50px;
                                }
                            }
                            .woman{
                                width: 1%;
                                flex: 1;
                                display: flex;
                                flex-direction: column;
                                text-align: center;
                                .woman-color{
                                    width: 100%;
                                    height: 12px;
                                    background: #564CED;
                                    border-radius: 7px;
                                    margin-top: 50px;
                                }
                            }
                        }
                        .gender-icon{
                            display: flex;
                            justify-content: space-between;
                            margin-top: 54px;
                            .gender-icon-left, .gender-icon-right{
                                display: flex;
                                flex-direction: column;
                                text-align: center;
                                &.gender-icon-left{
                                    i{
                                        color: #FF6A6A;
                                    }
                                }
                                &.gender-icon-right{
                                    i{
                                        color: #564CED;
                                    }
                                }
                                i{
                                    font-size: 40px;
                                }
                                span{
                                    font-size: 14px;
                                    color: #444444;
                                    line-height: 1;
                                    margin-top: 20px;
                                }
                            }
                        }
                    }
                }
            }
            .analysis-right {
                display: flex;
                flex-direction: column;
                width: 50%;
                height: 100%;
                .age-distribution {
                    padding-bottom: 20px;
                    .title {
                        padding-left: 32px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #333;
                    }
                }
            }
        }
        .analysis-bottom{
            display: flex;
            flex-direction: column;
            padding: 0 34px;
            .title{
                line-height: 1;
                margin-top: 58px;
            }
            .two-title{
                line-height: 1;
                display: flex;
                justify-content: space-between;
                padding: 0 14px 0 26px;
                color: #666666;
                font-size: 14px;
                margin-top: 50px;
            }
            .area-distribution{
                padding: 0 14px 0 26px;
                display: flex;
                flex-direction: column;
                .area-item-box{
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 20px;
                    .area-item{
                        display: flex;
                        align-items: center;
                        line-height: 1;
                        margin-top: 40px;
                        span{
                            color: #666666;
                            font-size: 14px;
                            &.area-name{
                                text-align: left;
                                width: 50px;
                            }
                            &.percentage{
                                width: 60px;
                                text-align: right;
                            }
                        }
                        .area-progress-box{
                            display: flex;
                            flex: 1;
                            width: 1%;
                            .area-progress-num{
                                height: 15px;
                                background: linear-gradient(45deg, #FFA72A 0%, #F8B95F 98%);
                                border-radius: 7px;
                            }
                        }
                    }
                }
            }
        }
        .null-data-area{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 200px;
        }
    }
</style>